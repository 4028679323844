import Alpine from 'alpinejs'
import { Turbo } from "@hotwired/turbo-rails"

window.Alpine = Alpine

import '~/controllers'
import '../src/libs/components.js'

document.addEventListener("DOMContentLoaded", () => {
  Alpine.start()
});

document.addEventListener("turbo:load", () => {
  Alpine.start()
});
