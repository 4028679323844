import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['monthly', 'annualy']

  connect() {
    this.showMonthly()
  }

  showMonthly() {
    this.toggleVisibility(this.monthlyTargets, false);
    this.toggleVisibility(this.annualyTargets, true);
    this.addActiveClass(0);
  }

  showAnnualy() {
    this.toggleVisibility(this.monthlyTargets, true);
    this.toggleVisibility(this.annualyTargets, false);
    this.addActiveClass(1);
  }

  toggleVisibility(targets, hidden) {
    targets.forEach(el => {
      el.hidden = hidden;
    });
  }

  addActiveClass(number) {
    const buttons = this.element.querySelectorAll('.pricing-button');
    buttons.forEach(el => {
      el.classList.remove('bg-white', 'border-gray-200');
    });
    buttons[number].classList.add('bg-white', 'border-gray-200');
  }
}
